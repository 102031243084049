<template>
  <div class="home">
    <mescroll-vue
      ref="mescroll"
      :down="mescrollDown"
      :up="mescrollUp"
      @init="mescrollInit"
      v-if="isData"
    >
      <div class="main">
        <div class="newsList" v-for="(item, index) in list" :key="index">
          <div
            class="newsList_center"
            v-if="!item.type"
            @click="goLanding(item.id)"
          >
            <div class="word">
              <div class="title">{{ item.title }}</div>
              <div class="newsInfo">
                <div>
                  {{ item.channel }}
                </div>
                <div class="round"></div>
                <div>
                  {{
                    $global.formatDate(item.publishedTime, "dd-MM-yyyy hh:mm")
                  }}
                </div>
              </div>
            </div>
            <div class="picurl">
              <img :src="item.headImg" alt="" />
            </div>
          </div>

          <template v-if="item.type && item.type === -1">
            <ins
              class="adsbygoogle adsItem"
              style="display: block"
              data-ad-client="ca-pub-9543177256867205"
              data-ad-slot="6671432129"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
          </template>
          <template v-if="item.type && item.type === -2">
            <ins
              class="adsbygoogle"
              style="display: block"
              data-ad-client="ca-pub-9543177256867205"
              data-ad-slot="6216733025"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
          </template>
          <!-- <template v-if="item.type && item.type === -3">
            <ins
              class="adsbygoogle"
              style="display: block"
              data-ad-client="ca-pub-9543177256867205"
              data-ad-slot="7939527167"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
          </template> -->

          <!-- <section></section> -->
        </div>
      </div>
    </mescroll-vue>
    <!-- <section class="no-data-row" v-if="!isData && requirDataAlready">
      <img src="@/assets/news/nodata.png" alt="" />
      <div class="no-data">
        Uh-oh. What you were looking for got away from you
      </div>
    </section> -->
    <div class="loading" v-if="!requirDataAlready">
      <img src="@/assets/news/loading.png" alt="" />
    </div>
  </div>
</template>
<script>
import '@/css/news/home.scss';
import MescrollVue from 'mescroll.js/mescroll.vue'
export default {
  name: "home",
  components: {
    MescrollVue
  },
  data() {
    return {
      list: [],
      hasNext: false,
      mescrollDown: { auto: false, callback: this.downCallback },
      mescrollUp: { auto: false, callback: this.upCallback, },
      dataShow: true,//默认有数据
      dataErr: false,//加载错误
      mescroll: null,
      page: 1,
      cid: 94,
      type: '',
      isinitialization: false,
      isData: false,
      requirDataAlready: true

    }
  },
  mounted() {
    this.type = this.$route.query.type
    console.log(this.type)
    const ENV = process.env.NODE_ENV
    if (ENV === 'production') {
      const typeToCidMap = {
        'Economy': 94,
        'Gaming': 104,
        'Sports': 97,
        'Entertainment': 96,
        'Beauty': 106,
        'Health': 99,
        'Films': 105
      };
      this.cid = typeToCidMap[this.type] || 94;

    } else {
      this.cid = 94
    }
    this.$store.commit('UPDATE_CID', this.cid)

    this.$eventrack("show_h5", 'expose', {
      extensions: JSON.stringify({
        pve_cur: "/h5page/x/x/",
        cid: this.cid,
        type: this.type
      })
    });
    this.$eventrackFb("show_page_h5", 'expose', {
      extensions: JSON.stringify({
        pve_cur: "/h5page/x/x/",
        cid: this.cid,
        type: this.type
      }),
      pve_cur: "/h5page/x/x/",
      cid: this.cid,
      type: this.type
    });
    this.getList()
    setTimeout(() => {
      window.addAds();
    }, 1000)
    // this.$nextTick(() => {
    //   window.addAds();
    // })
  },
  methods: {
    getList() {
      console.log("getList")
      return this.$api.getList({
        page: this.page,
        cid: this.cid,
        sdu: 2,
        size: 20,
      }).then((res
      ) => {
        if (res.result_code === 200) {
          let data = res.data.source
          this.list.push(...data)

          if (this.list.length > 8 && !this.list[3].type) {
            this.list.splice(3, 0, {
              type: -1
            })
          }
          if (this.list.length > 12 && !this.list[8].type) {
            this.list.splice(8, 0, {
              type: -2
            })
          }
          // if (this.list.length > 15 && !this.list[12].type) {
          //   this.list.splice(12, 0, {
          //     type: -3
          //   })
          // }
          // const indicesToInsert = [3, 8, 12];
          // for (const index of indicesToInsert) {
          //   if (this.list.length > index + 3 && !this.list[index].type) {
          //     this.list.splice(index + 1, 0, { type: -1 });
          //   }
          // }
          if (this.list.length > 0) {
            this.isData = true
          }
          this.hasNext = res.data.hasNext
        } else {
          this.requirDataAlready = true
        }
        if (!this.isinitialization) {
          this.isinitialization = true
          this.$eventrack("show_h5", 'expose', {
            extensions: JSON.stringify({
              pve_cur: "/h5page/news/x/",
              type: this.type,
              cid: this.cid,
              state: res.result_code === 200 && res.data.source.length > 1 ? 1 : 0
            })
          });
          this.$eventrackFb("show_pageNews_h5", 'expose', {
            extensions: JSON.stringify({
              pve_cur: "/h5page/news/x/",
              type: this.type,
              cid: this.cid,
              state: res.result_code === 200 && res.data.source.length > 1 ? 1 : 0
            }),
            pve_cur: "/h5page/news/x/",
            type: this.type,
            cid: this.cid,
            state: res.result_code === 200 && res.data.source.length > 1 ? 1 : 0
          });
        }
      }).catch((err) => {
        this.requirDataAlready = true
        this.isinitialization = true
        this.$eventrack("show_h5", 'expose', {
          extensions: JSON.stringify({
            pve_cur: "/h5page/news/x/",
            type: this.type,
            cid: this.cid,
            state: -1
          })
        });
        this.$eventrackFb("show_pageNews_h5", 'expose', {
          extensions: JSON.stringify({
            pve_cur: "/h5page/news/x/",
            type: this.type,
            cid: this.cid,
            state: -1
          }),
          pve_cur: "/h5page/news/x/",
          type: this.type,
          cid: this.cid,
          state: -1
        });
      });
    },
    goLanding(id) {
      this.$eventrack("clicknews_h5", 'click', {
        extensions: JSON.stringify({
          id: id,
          cid: this.cid,
        })
      });
      this.$eventrackFb("clicknews_h5", 'click', {
        extensions: JSON.stringify({
          id: id,
          cid: this.cid,
        }), id: id,
        cid: this.cid,
      });
      this.$router.push({ name: 'landing', query: { id: id } })

    },
    mescrollInit(mescroll) {
      this.mescroll = mescroll;
      mescroll.downCallback = this.downCallback;
    },
    downCallback() {
      console.log('自动下拉刷新 ')
      this.list = []
      this.page = 0
      this.getList()
        .then(() => {
          this.$nextTick(() => {
            this.mescroll.endSuccess(); // 结束下拉刷新,无参
          });
        })
        .catch(() => {
          this.mescroll.endErr();
        });

      // this.$nextTick(() => {
      //   window.addAds();
      // })
    },
    // 上拉加载
    upCallback() {
      console.log('自动上拉记载', this.list)
      this.page = this.page + 1

      this.getList().then(() => {
        this.$nextTick(() => {
          console.log(this.hasNext)
          let length = this.list.length + 11
          this.mescroll.endSuccess(length, this.hasNext)
        })
      }).catch(() => {
        this.mescroll.endErr()
      })
    },
    // backToTop() {
    //   this.$refs.mescroll.scrollTo(0, 0, 1000);
    //   this.isBackToTop = false
    // },
  }
};
</script>